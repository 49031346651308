import styled from 'styled-components'

import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { borderColor } from 'styles/colors'
import { H3 } from 'styles/typography'

export const FaqWrap = styled.div`
  position: static;

  ${media.medium`
    display: flex;
  `};
`

export const FaqContent = styled.div`
  display: block;
  width: 100%;

  ${media.medium`
    width: 70%;
    min-height: 500px;
  `};
`

export const FaqContentTitle = styled(H3)`
  margin-bottom: ${spacing.large};

  ${media.medium`
    margin-bottom: ${spacing.xLarge};
  `};

  ${media.large`
    margin-bottom: ${spacing.xxLarge};
  `};
`
