/* eslint no-irregular-whitespace: 0 */

const Faq = {
  categories: [
    {
      label: 'Device/How It Works',
      id: 'device',
      active: true
    },
    {
      label: 'neo Sticks',
      id: 'neoSticks',
      active: false
    },
    {
      label: 'Where to Buy',
      id: 'whereToBuy',
      active: false
    },
    {
      label: 'Warranty & Returns',
      id: 'warrantyReturns',
      active: false
    },
    {
      label: 'My Account',
      id: 'myAccount',
      active: false
    },
    {
      label: 'Menthol Ban',
      id: 'mentholBan',
      active: false
    }
  ],
  device: {
    title: 'Device/How It Works',
    content: [
      {
        question: '1. What is glo?',
        response: `<p><strong>glo</strong> is an advanced tobacco heating system with a simple and intuitive design for adult tobacco consumers. The technology heats tobacco, rather than burning it. This quality does not mean that this product is less harmful than other tobacco products.</p>`
      },
      {
        question: '2. Is it similar to a vape device/e-cigarette? What is the difference?',
        response: `<p><strong>glo</strong>&nbsp;is an advanced tobacco heating system.&nbsp;<strong>glo</strong>&nbsp;uses real tobacco and is designed to work exclusively with&nbsp;<strong>neo</strong>.&nbsp;<strong>neo</strong>&nbsp;sticks are heated and generate an aerosol (vapour) from heating the tobacco. An e-cigarette typically does not contain tobacco and uses a flavoured liquid (with or without nicotine) that, when heated in the device, generates a vapour.</p>`
      },
      {
        question: '3. How does the device work?',
        response: `
          <p>To use the device, adult tobacco consumers must:</p>
          <ol>
            <li>Open the shutter on top of the&nbsp;<strong>glo</strong>&nbsp;tobacco heater and gently insert a&nbsp;<strong>neo</strong>&nbsp;&nbsp;stick&nbsp;with a slight twist action until the colour of the tipping point is no longer visible.</li>
            <li>Hold down the start (“on”) button for 3 seconds to start the heating process. The device will gently vibrate after 3 seconds, indicating the button can be let go.</li>
            <li>After 40 seconds, all 4 lights on the&nbsp;<strong>glo</strong>&nbsp;tobacco heater will be illuminated and the device will vibrate to indicate that it’s ready to use.</li>
            <li>Leave the&nbsp;<strong>neo</strong>&nbsp;stick&nbsp;in the&nbsp;<strong>glo</strong>&nbsp;device, and inhale gently. Each session lasts 3.5 minutes.</li>
            <li>3 of the 4 lights will turn off and the device will gently vibrate to indicate that the session is coming to an end.</li>
            <li>The&nbsp;<strong>glo</strong>&nbsp;tobacco heater will then turn off automatically.</li>
            <li>You may stop your session at any point by holding down the control button for 3 seconds on the&nbsp;<strong>glo</strong>&nbsp;tobacco heater. The device will gently vibrate to let you know it is turning off.</li>
          </ol>
          <p>Note that the session cannot be paused. The existing&nbsp;<strong>neo</strong>&nbsp;stick&nbsp;must be removed and replaced before the next session can begin.</p>
          <p>NOTE: The&nbsp;<strong>neo</strong>&nbsp;stick&nbsp;should remain in the&nbsp;<strong>glo</strong>&nbsp;device while inhaling.</p>
        `
      },
      {
        question: '4. Does glo need charging?',
        response: `<p>Yes, it needs to be charged with a USB cable (provided with the purchase of&nbsp;<strong>glo</strong>). Insert the USB cable into the USB charger port of the&nbsp;<strong>glo</strong>&nbsp;tobacco heater and connect with a computer’s USB port or a USB plug. The lights on the control button will indicate the charging status. It takes 2-4 hours to fully charge the battery, which will provide&nbsp;up to 30&nbsp;sessions. To check the battery status press and release the control button on the side of the&nbsp;<strong>glo</strong>&nbsp;tobacco heater. [The status of the battery cannot be checked during a session].</p>`
      },
      {
        question: '5. How long does glo last from a single charge? / How often does it need to be charged.',
        response: `<p><strong>glo</strong>&nbsp;lasts up to 30 consecutive sessions from a single charge.</p>`
      },
      {
        question: '6. Is glo the same as IQOS?',
        response: `<p>Both are tobacco heating devices. However, the design and technology of&nbsp;<strong>glo</strong>&nbsp;is&nbsp;different from IQOS. In addition,&nbsp;<strong>glo</strong>&nbsp;is made to work exclusively with&nbsp;<strong>neo</strong>&nbsp;sticks.</p>`
      },
      {
        question: '7. Does glo work with cigarettes?',
        response: `<p>No. glo is made to work exclusively with <strong>neo</strong> sticks.</p>`

      },
      {
        question: '8. Can the glo device be used indoors?',
        response: `<p>This depends on the by-laws for vaping and smoking in each city.</p>`
      },
      {
        question: '9. What brand is made to work exclusively with glo?',
        response: `<p>neo</p>`
      },
      {
        question: '10. Is there any ash? / Why is there no ash?',
        response: `<p>Because the tobacco is not burned, no ash is produced.</p>`
      },
      {
        question: '11. What does “Advanced Heat Technology™” mean?',
        response: `<p>The technology heats to the optimum temperature to deliver the true taste of tobacco.</p>`
      },
      {
        question: '12. What should be done if glo accessories are broken?',
        response: `<p>Please <a href="/contact-us">contact us</a> so that we can help you accordingly.</p>`
      },
      {
        question: '13. What should be done if glo accessories are broken?',
        response: `<p>Please <a href="/contact-us">contact us</a> so that we can help you accordingly.</p>`
      },
      {
        question: '14. What is the general mechanism of glo?',
        response: `<p>The glo device has a tube into which a heatable tobacco rod is inserted. On activation, a battery powered heating chamber heats the tobacco.</p>`
      },
      {
        question: '15. What is the difference between glo and combustible tobacco?',
        response: `<p>Heating <strong>neo</strong> sticks with <strong>glo</strong> is a completely new way to consume tobacco. <strong>glo</strong> generates an aerosol (vapour) from heating the tobacco, while combustible products generate smoke by burning the tobacco.</p>`
      },
      {
        question: '16. Is using glo and neo healthier than smoking a regular cigarette?',
        response: `<p><strong>neo</strong> sticks do not combust tobacco, deliver no ash and less odour than a cigarette. However, this does not mean that this product produces less adverse health effects than other tobacco products. Tobacco use causes harm to your health and is addictive.</p>`
      },
      {
        question: '17. Is there any difference in addiction level between cigarettes and heated tobacco?',
        response: `<p>Both products contain nicotine and nicotine is an addictive substance. Tobacco use causes harm to your health and is addictive.</p>`
      },
      {
        question: '18. Is this safer than a cigarette – yes or no?',
        response: `<p>We are <u>not</u> saying that <strong>glo</strong> produces less adverse health effects than other tobacco products. Tobacco use causes harm to your health and is addictive. <strong>glo</strong> does not burn tobacco and more research is needed before any statements can be made about relative safety.</p>`
      },
      {
        question: '19. Can the device and neo sticks be sampled/tried before I purchase it?',
        response: `<p>Sampling of tobacco products is not permitted.</p>`
      },
      {
        question: '20. How do you clean the device?',
        response: `<p>Ensure the <strong>glo</strong> tobacco heater has been allowed to cool for a minimum of 5 minutes before cleaning. WARNING: Attempting to remove debris by firmly tapping or shaking the <strong>glo</strong> tobacco heater may result in damage. Clean the inside of the heating chamber using the brush provided. For best results, clean from both ends as required. For best performance, it needs to be cleaned after 20 sessions.</p>`
      },
      {
        question: '21. Are you suggesting all smokers to switch from cigarettes to heated tobacco?',
        response: `<p>We are not suggesting one product over another.</p>`
      },
      {
        question: '22. Where should I dispose the device after the end of its life cycle?',
        response: `<p>The <strong>glo</strong> device should be disposed of in line with local regulations on disposal and recycling of electronic equipment and lithium ion batteries.</p>`
      },
      {
        question: '23. How often should I take care of and clean the device for best performance?',
        response: `<p>Remember to clean your device on a regular basis. For best performance, it needs to be cleaned after 20 sessions. Ensure the <strong>glo</strong> tobacco heater has been allowed to cool for a minimum of 5 minutes before cleaning. Clean the inside of the heating chamber using the brush provided. For best results, clean from both ends as required.  WARNING: Attempting to remove debris by firmly tapping or shaking the glo tobacco heater may result in damage.</p>`
      },
      {
        question: '24. Do I use the glo tobacco heater the same way as I smoke?',
        response: `<p>Place the <strong>neo</strong> stick to your lips and inhale gently, like a regular cigarette. Each session lasts for 3.5 minutes.</p>`
      }
    ]
  },
  neoSticks: {
    title: 'neo Sticks',
    content: [
      {
        question: "25. What are neo sticks?",
        response: `<p><strong>neo</strong> sticks are heatable tobacco sticks. They are designed to be used exclusively with <strong>glo</strong>. When used with <strong>glo</strong>, <strong>neo</strong> sticks become heated for the real taste of tobacco. They are designed to be heated, not burned. Do not light. This quality does not mean that this product is less harmful than other tobacco products.</p>`
      },
      {
        question: "26. Is the taste different than a traditional cigarette? / Why does it taste different than a regular cigarette?",
        response: `<p><strong>neo</strong> sticks are not cigarettes. The taste is different because the <strong>neo</strong> sticks are heated not burned. When used with <strong>glo</strong>, <strong>neo</strong> sticks become heated for the real taste of tobacco. <strong>neo</strong> sticks should not be lit.</p>`
      },
      {
        question: "27. What are neo sticks made of?",
        response: `<p>They are made with tobacco and are designed to be heated not burned.</p>`
      },
      {
        question: "28. What is the tar level of neo sticks?",
        response: `<p><strong>neo</strong> sticks are Heatable tobacco sticks; they are not cigarettes. Tar is related to the combustion of tobacco. <strong>neo</strong> sticks are heated and generate an aerosol (vapour) from heating the tobacco.</p>`
      },
      {
        question: "29. How does it taste? / Do neo sticks taste like a cigarette?",
        response: `<p><strong>neo</strong> sticks are Heatable tobacco sticks; they are not cigarettes. When neo sticks are heated, they deliver the real taste of tobacco.</p>`
      },
      {
        question: "30. Can neo sticks be burned like a regular cigarette?",
        response: `<p><strong>neo</strong> sticks are not cigarettes. They should not be lit/burned or used on their own. They are made to be heated with the <strong>glo</strong> device, not burned. Do not light.</p>`
      },
      {
        question: "31. How should neo sticks be stored?",
        response: `<p>The <strong>neo</strong> sticks packs should be stored in a cool dry place.</p>`
      },
      {
        question: "32. What are the variants of neo sticks, and what are their taste profiles?",
        response: `<p>Please see the <a href="/collections/neostiks">product catalogue</a> for variant details.</p>`
      },
      {
        question: "33. Can neo sticks be used if they are dropped in water?",
        response: `<p>If the pack is wrapped with the poly/film, open the pack and if the <strong>neo</strong> sticks are dry, they are ok to use. If they are wet, they should be disposed of, as they will give a suboptimal sensory experience.</p>`
      },
      {
        question: "34. How are neo sticks used?",
        response: `<p>The <strong>neo</strong> stick must be inserted in the <strong>glo</strong> device and left inside the device while inhaling.</p>`
      },
      {
        question: "35. Why do neo sticks turn brown after being heated with the glo?",
        response: `<p>This is a result of the heating process.</p>`
      },
      {
        question: "36. Can neo sticks be re-used?",
        response: `<p>It is not recommended, as it will compromise the taste.</p>`
      },
      {
        question: "37. Where should I dispose the consumables after usage?",
        response: `<p>Bin or ashtray</p>`
      }
    ]
  },
  whereToBuy: {
    title: 'Where to Buy',
    content: [
      {
        question: "38. Besides this website, where can adult tobacco consumers purchase glo?",
        response: `<p>Please use our <a href='/store-locator'>store locator</a> to find a store near you.</p>`
      }
    ]
  },
  warrantyReturns: {
    title: 'Warranty & Returns',
    content: [
      {
        question: "39. Is there a manufacturer’s warranty on the glo device?",
        response: `<p>The device does not come with a manufacturer’s warranty. However, the manufacturer will exchange the device if it is not fit for merchantable use (i.e. in case of defects/damaged upon delivery) for a reasonable time after purchase.</p>`
      },
      {
        question: "40. Can neo sticks be returned or exchanged for dislike?",
        response: `<p><strong>neo</strong> sticks are tobacco products, therefore they cannot be returned for dislike. They are final sale.</p>`
      }
    ]
  },
  myAccount: {
    title: 'My Account',
    content: [
      {
        question: "41. How do I close my account?",
        response: `
          <p>Please contact us to request a close of your account, after carefully reading the below information. </p>
          <p>Please note that if you use your login on other Imperial Tobacco platforms, you will lose access to those accounts.</p>
          <p>Your profile and account information, order history and subscription preferences will be deleted.</p>
          <p>Returns and refunds will not be able to be processed for orders on closed accounts. All open orders will be cancelled.</p>
        `
      }
    ]
  },
  mentholBan: {
    title: 'Menthol Ban',
    content: [
      {
        question: "42. Why are menthol neo sticks not available anymore?",
        response: `
          <p>The Federal government has changed the legislation so that menthol Heatable Tobacco Sticks are now prohibited for sale. This change came into effect on November 19th, 2018. From now on, neo sticks (used with glo) containing menthol are no longer available.</p>
          <p>This change affected both Fresh Mix and Citrus Mix variants.</p>
        `
      },
      {
        question: "43. I used to use Fresh Mix/Citrus Mix. What flavour of neo sticks should I buy now?",
        response: `<p>Former Fresh Mix/Citrus Mix consumers are recommended to try Rich Tobacco, our newest flavor of neo sticks.</p>`
      },
      {
        question: "44. What flavors of neo sticks are available now?",
        response: `
          <p>Smooth Tobacco and Rich Tobacco neo sticks are still available!</p>
          <ul>
            <li>Rich Tobacco - A deeply satisfying taste of true tobacco with rich, warm notes for a complex sensorial experience.</li>
            <li>Smooth Tobacco - A deeply satisfying taste of true tobacco with bright, roasted notes for a balanced sensorial experience.</li>
          </ul>
        `
      },
      {
        question: "45. Are Fresh Mix/Citrus Mix neo sticks available for sale in-store?",
        response: `<p>No menthol Tobacco Heatable Sticks are available for sale in Canada, neither in-store or online.</p>`
      },
      {
        question: "46. Is my glo device changing as well?",
        response: `<p>No, there are no changes to your glo device. It is the same tobacco heating technology offered in four different colours: graphite, blue, pink, and champagne. Our Elements Edition devices are still available for a limited time as well.</p>`
      },
      {
        question: "47. I have more questions. Who can I speak to?",
        response: `<p>Please click <a href="/contact-us">here</a> to leave us a message and we’ll get back to you as soon as possible.</p>`
      }
    ]
  }
}

export default Faq
