import React from 'react'
import styled, { css } from 'styled-components'
import { array, func } from 'prop-types'
import { stripUnit } from 'polished'

import { primaryColor, borderColor, greyDark } from 'styles/colors'
import { media, mediaDown } from 'styles/media'
import { fontSizes } from 'styles/typography'
import { animationTime, animationCurve } from 'styles/global'
import * as spacing from 'styles/spacing'

const Container = styled.div`
  position: relative;
  width: 30%;
  flex-shrink: 0;
  border-left: 1px solid ${borderColor};
  align-self: flex-start;

  ${mediaDown.medium`
    display: none;
  `};
`

const Item = styled.button`
  display: block;
  width: 100%;
  text-align: left;
  padding: ${stripUnit(spacing.small) * 1.5 + 'px'} ${stripUnit(spacing.medium) * 1.25 + 'px'};
  box-shadow: inset 0 0 0 ${primaryColor};
  font-size: ${fontSizes.small};
  transition: color ${animationTime} ${animationCurve}, box-shadow ${animationTime} ${animationCurve};

  &:hover {
    color: ${greyDark};
  }

  ${props =>
    props.active &&
    css`
      box-shadow: inset 2px 0 0 ${primaryColor};
      color: ${primaryColor};

      &:hover {
        color: ${primaryColor};
      }
    `}
`

const FaqSidebar = ({ items, onItemClick }) => (
  <Container>
    {items.map(item => (
      <Item
        key={item.id}
        id={item.id}
        active={item.active}
        onClick={() => {
          onItemClick(item.id)
        }}
      >
        {item.label}
      </Item>
    ))}
  </Container>
)

FaqSidebar.propTypes = {
  items: array.isRequired,
  onItemClick: func.isRequired
}

export default FaqSidebar
