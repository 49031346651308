import React, { Component } from 'react'
import Helmet from 'react-helmet'

import Faq from '../utils/faq'

import Layout from 'components/Layout'
import Section, { SectionBlock } from 'components/Section'
import Wrapper from 'components/Wrapper'
import Question from 'components/Question'
import FaqSidebar from 'components/FaqSidebar'
import { FaqWrap, FaqContent, FaqContentTitle } from 'components/FaqComponents'
import Dropdown from 'components/Dropdown'
import RichText from 'components/RichText'
import { H1 } from 'styles/typography'

class FaqComponent extends Component {
  state = {
    categories: Faq.categories,
    selectedId: Faq.categories.find(category => category.active).id
  }

  handleCategoryClick = id => {
    let selectedId

    const categories = this.state.categories.map(category => {
      if (category.id === id) {
        category.active = true
        selectedId = category.id
      } else {
        category.active = false
      }

      return category
    })

    this.setState({ categories, selectedId })
  }

  handleCategorySelect = e => {
    this.handleCategoryClick(e.target.value)
  }

  render() {
    const { selectedId, categories } = this.state

    let dropdownOptions = {}
    categories.forEach(category => {
      dropdownOptions[category.label] = category.id
    })

    return (
      <Layout>
        <Helmet title="What are heated tobacco products? What is glo? - FAQs - glo" />

        <Section>
          <Wrapper>
            <SectionBlock className="u-text-center">
              <H1>FAQs</H1>
            </SectionBlock>

            <SectionBlock className="u-hide-medium-up">
              <Dropdown
                options={dropdownOptions}
                onChange={this.handleCategorySelect}
                full />
            </SectionBlock>

            <SectionBlock>
              <FaqWrap>
                <FaqSidebar
                  items={categories}
                  onItemClick={this.handleCategoryClick} />
                <FaqContent>
                  <FaqContentTitle>{Faq[selectedId].title}</FaqContentTitle>
                  {Faq[selectedId].content.map(item => (
                    <Question question={item.question}>
                      <RichText>
                        <p dangerouslySetInnerHTML={{ __html: item.response }} />
                      </RichText>
                    </Question>
                  ))}
                </FaqContent>
              </FaqWrap>
            </SectionBlock>
          </Wrapper>
        </Section>
      </Layout>
    )
  }
}

export default FaqComponent
